import { useMutation } from "react-query";
import Swal from "sweetalert2";
import { useSnapshot } from "valtio";
import chatModalStore from "../../../ecommerce/store/ChatModalStore";
import {
  createSalesConsultancy,
  sendNotification,
} from "../../../supplierEcommerce/services/api";

export const useCreateSalesConsultancy = () => {
  const { setChatHashid } = useSnapshot(chatModalStore);
  return useMutation({
    mutationFn: ({
      salespersonUuid,
      salespersonOnlineStatus,
      productId,
    }: {
      salespersonUuid: string;
      salespersonOnlineStatus: string;
      productId: number;
    }) =>
      createSalesConsultancy(
        salespersonUuid,
        salespersonOnlineStatus,
        productId
      ),
    onSuccess: (data: CreateSalesConsultancyResponse) => {
      const chatHashid = data.salesConsultancy.hashid;
      if (chatHashid) {
        setChatHashid(chatHashid);
      }
    },
    onError: (error) => {
      console.error("Error al crear la asesoría virtual:", error);
    },
  });
};

export const useSendNotification = () => {
  return useMutation({
    mutationFn: ({ hashid }: { hashid: string }) => sendNotification(hashid),
    onSuccess: () => {
      Swal.fire({
        title: "Notificación enviada",
        text: "El asesor ha sido notificado. Puedes enviar otra alerta en 5 minutos si es necesario.",
        icon: "success",
        confirmButtonText: "¡Genial, entendido!",
      });
    },
    onError: () => {
      Swal.fire({
        title: "Espera un momento",
        text: "Debes esperar 5 minutos antes de enviar otra notificación.",
        icon: "warning",
        confirmButtonText: "De acuerdo",
      });
    },
  });
};
