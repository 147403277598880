import Grid from "@material-ui/core/Grid";
import React from "react";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import SalesConsultanciesCard from "../SalesConsultanciesCard/SalesConsultanciesCard";
import { SearchX } from "lucide-react";

type SalesConsultanciesListProps = {
  salesConsultancies: SalesConsultancy[];
};

const SalesConsultanciesList = ({
  salesConsultancies,
}: SalesConsultanciesListProps) => {
  if (salesConsultancies.length === 0) {
    return (
      <EmptyState
        image={<SearchX size={48} />}
        title={"No se encontraron resultados"}
        message={"Intenta con palabras clave diferentes."}
      />
    );
  }
  return (
    <Grid container spacing={2} style={{ marginTop: "1.5rem" }}>
      {salesConsultancies.map((consultancy) => (
        <Grid key={consultancy.id} item xs={12} sm={6} md={3}>
          <SalesConsultanciesCard consultancy={consultancy} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SalesConsultanciesList;
