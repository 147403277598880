import Avatar from "@material-ui/core/Avatar";
import React from "react";
import { roles, useAuth } from "../../services/auth";
import { shortenText } from "../../utils/utils";
import ChatHeaderActions from "./ChatHeaderActions";
import ChatHeaderControls from "./ChatHeaderControls";
import styles from "./EcommerceChat.module.scss";

type ChatHeaderProps = {
  showControls: boolean;
  clientInfo: ClientInfo | null;
  salespersonInfo: SalesPersonInfo | null;
  clientAvatar: string;
  salesAvatar: string;
};

const ChatHeader = ({
  showControls,
  clientInfo,
  salespersonInfo,
  clientAvatar,
  salesAvatar,
}: ChatHeaderProps) => {
  const { currentRoleId } = useAuth();
  let personName =
    currentRoleId !== 1
      ? `${clientInfo?.first_name || "Nombre"} ${
          clientInfo?.last_name || "Apellido"
        }`
      : `${salespersonInfo?.first_name || "Nombre"} ${
          salespersonInfo?.last_name || "Apellido"
        }`;
  personName = shortenText(personName, 15);
  return (
    <div className={styles.chatHeader}>
      <div className={styles.chatHeaderInfo}>
        <div className={styles.data}>
          {currentRoleId !== roles.client ? (
            <Avatar sizes="">{clientAvatar}</Avatar>
          ) : (
            <Avatar alt="Remy Sharp" src={salesAvatar} />
          )}
        </div>
        {personName && <h3 className="subtitle-2">{personName}</h3>}
      </div>
      <ChatHeaderActions />
      {showControls && <ChatHeaderControls />}
    </div>
  );
};

export default ChatHeader;
