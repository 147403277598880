import { useSnapshot } from "valtio";
import chatModalStore from "../../ecommerce/store/ChatModalStore";
import { roles, useAuth } from "../../services/auth";
import CircularButton from "../CircularButton/CircularButton";
import KnockButton from "../KnockButton/KnockButton";
import { Video } from "lucide-react";
import { useHistory } from "react-router-dom";

import styles from "./EcommerceChat.module.scss";

const ChatHeaderActions = () => {
  const history = useHistory();
  const { chatHashid } = useSnapshot(chatModalStore);
  const { currentRoleId } = useAuth();
  const handleNavigation = () => {
    const PATH = `/videochat/sales-consultancy/${chatHashid}/${currentRoleId}`;
    if (currentRoleId === roles.client) {
      window.open(PATH, "_blank");
    } else {
      history.push(PATH);
    }
  };
  return (
    <div className={styles.chatHeaderActions}>
      <CircularButton
        icon={Video}
        tooltipName={"Video Chat"}
        onClick={handleNavigation}
      />
      {currentRoleId === roles.client && (
        <KnockButton chatHashid={chatHashid!} />
      )}
    </div>
  );
};

export default ChatHeaderActions;
