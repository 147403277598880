import { proxy } from "valtio";

export interface ChatModalStoreProps {
  isOpen: boolean;
  selectedChatId: number | null;
  salesPersonUuid: string | null;
  chatHashid: string | null;
  isMinimized: boolean;
  selectedSalesPerson: SalesPeople | null;
  setChatHashid: (chatHashid: string) => void;
  setIsMinimized: (isMinimized: boolean) => void;
  setSelectedSalesPerson: (salesPeople: SalesPeople | null) => void;
  onOpen: () => void;
  onClose: () => void;
  reset: () => void;
}

const chatModalStore = proxy<ChatModalStoreProps>({
  isOpen: false,
  selectedChatId: null,
  salesPersonUuid: null,
  chatHashid: null,
  isMinimized: false,
  selectedSalesPerson: null,
  setChatHashid: (chatHashid: string) => {
    chatModalStore.chatHashid = chatHashid;
  },
  onOpen: () => {
    chatModalStore.isOpen = true;
  },
  setIsMinimized: (isMinimized: boolean) => {
    chatModalStore.isMinimized = isMinimized;
  },
  setSelectedSalesPerson: (salesPeople: SalesPeople | null) => {
    chatModalStore.selectedSalesPerson = salesPeople;
  },

  onClose: () => {
    chatModalStore.isOpen = false;
    chatModalStore.reset();
  },
  reset: () => {
    chatModalStore.selectedChatId = null;
    chatModalStore.salesPersonUuid = null;
  },
});

export default chatModalStore;
