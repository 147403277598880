import Axios from "axios";
import { handleError } from "../../utils/utils";

export async function getFavoritesProducts() {
  try {
    const favoritesProducts = await Axios.get(`/v2/favorite`);

    if (!favoritesProducts)
      throw Error("No se encontraron productos favoritos");

    return favoritesProducts.data;
  } catch (error) {
    handleError(error);
  }
}

export async function addProductToFavorites(productId: number) {
  try {
    await Axios.post(`/v2/favorite/add/${productId}`);
  } catch (error) {
    handleError(error);
  }
}

export async function removeProductFromFavorites(productId: number) {
  try {
    await Axios.delete(`/v2/favorite/remove/${productId}`);
  } catch (error) {
    handleError(error);
  }
}

export async function getAllProducts(
  companyName: string,
  filter: Filter,
  sort: string,
  pagination: { currentPage: number; totalPages: number }
) {
  try {
    // Create the query string based on filters, sorting, and pagination
    let queryString = "";

    // Filters (color, brand, material, price)
    for (let key in filter) {
      const filterValues = filter[key as keyof Omit<Filter, "price">];
      if (Array.isArray(filterValues) && filterValues.length > 0) {
        queryString += `${key}=${filterValues.join(",")}&`;
      }
    }

    // Price (a special case within the filter)
    if (filter.price) {
      queryString += `price_gte=${filter.price[0]}&price_lte=${filter.price[1]}&`;
    }

    // Sorting
    if (sort && sort !== "none") {
      queryString += `_sort=${sort}&`;
    }

    // Pagination
    queryString += `_page=${pagination.currentPage}&_limit=12&`;

    const response = await Axios.get(
      `/v2/products/${companyName}/products?${queryString}`
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getFilteredProducts(companyName: string, filter: any) {
  try {
    // Create the query string based on filters, sorting, and pagination
    let queryString = "";

    // Filters (color, brand, material, price)
    for (let key in filter) {
      const filterValues = filter[key as keyof Omit<Filter, "price">];
      if (Array.isArray(filterValues) && filterValues.length > 0) {
        queryString += `${key}=${filterValues.join(",")}&`;
      }
    }

    // Price (a special case within the filter)
    if (filter.price) {
      queryString += `price_gte=${filter.price[0]}&price_lte=${filter.price[1]}&`;
    }
    const filters = await Axios.get(
      `/v2/products/${companyName}/filters?${queryString}`
    );
    return filters.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getProductById(productId: number) {
  try {
    const product = await Axios.get(`/v2/products/${productId}`);
    return product.data;
  } catch (error) {
    handleError(error);
  }
}

export async function GetAllCompanyPreferences(companyName: string) {
  try {
    const companyPreferences = await Axios(
      `/v2/suppliers/e-commerce/${companyName}`
    );

    return companyPreferences.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getAllSalesPersonByCompanyName(companyName: string) {
  try {
    companyName = companyName.replace(/-/g, " ");
    const salesPersons = await Axios.get(`/v2/salesperson/${companyName}`);
    return salesPersons.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getSalesPeopleById(salesPersonId: number) {
  try {
    const salesPeople = await Axios.get(
      `/v2/salespeople/salesperson/${salesPersonId}`
    );
    return salesPeople.data;
  } catch (error) {
    handleError(error);
  }
}

export async function sendClientContactInfo(contactInfo: IContactInfo) {
  try {
    let response = await Axios.post(`/v2/salespeople/contact`, contactInfo);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getUserProfile() {
  try {
    const userProfile = await Axios.get(`/v1/users/profile`);
    return userProfile.data;
  } catch (error) {
    handleError(error);
  }
}

export async function updateUserProfileStatus(onlineStatus: string) {
  try {
    const userProfile = await Axios.patch(`/v2/salespeople/onlineStatus`, {
      onlineStatus,
    });
    return userProfile.data;
  } catch (error) {
    handleError(error);
  }
}
