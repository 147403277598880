import React from "react";
import styles from "./emptyState.module.scss";

interface EmptyStateProps {
  title: string;
  message: string;
  image: string | React.ReactNode;
  children?: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  message,
  image,
  children,
}) => {
  return (
    <div className={styles.emptyState}>
      {typeof image === "string" ? (
        <div className={styles.image}>
          <img src={image} alt="Empty state illustration" />
        </div>
      ) : (
        <div className={styles.icon}>{image}</div>
      )}
      <h3 className={styles.title}> {title}</h3>
      <p className={styles.message}>{message}</p>
      {children && <div className={styles.children}>{children}</div>}
    </div>
  );
};

export default EmptyState;
