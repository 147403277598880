import React from "react";
import { X, Minus } from "lucide-react";
import { useSnapshot } from "valtio";
import chatModalStore from "../../ecommerce/store/ChatModalStore";
import styles from "./EcommerceChat.module.scss";

const ChatHeaderControls = () => {
  const { setIsMinimized, setSelectedSalesPerson } =
    useSnapshot(chatModalStore);
  return (
    <div className={styles.chatControl}>
      <button
        onClick={() => setIsMinimized(true)}
        className={styles.chatControlButton}
      >
        <Minus size={20} />
      </button>
      <button
        onClick={() => setSelectedSalesPerson(null)}
        className={styles.chatControlButton}
      >
        <X size={20} />
      </button>
    </div>
  );
};

export default ChatHeaderControls;
