import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import { useState } from "react";
import SearchBar from "../../../components/SearchBar/SearchBar";
import { useDebounce } from "../../../hooks/useDebounce";
import { useGetSalesConsultancies } from "../../../utils/react-query/querySalesConsultancy/queries";
import SalesConsultanciesList from "./SalesConsultanciesList/SalesConsultanciesList";

type SalesConsultanciesProps = {
  status: boolean;
};
const SalesConsultancies = ({ status }: SalesConsultanciesProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = useDebounce(searchTerm, 500);
  const [page, setPage] = useState(1);
  const limit = 12;
  const { data, isLoading: isLoadingSalesConsultancies } =
    useGetSalesConsultancies(status, page, limit, debouncedSearch);

  if (isLoadingSalesConsultancies) {
    return <div>Cargando asesorías...</div>;
  }

  return (
    <section className="layout-dashboard-container">
      <div>
        <h4 className="header-title">
          Asesorias {!status ? "activas" : "completadas"}
        </h4>
        <SearchBar value={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      <SalesConsultanciesList salesConsultancies={data.salesConsultancies} />

      {data.salesConsultancies.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }} mt={8} mb={2}>
          <Pagination
            shape="rounded"
            count={data?.totalPages || 1}
            page={page}
            onChange={(_, value) => setPage(value)}
            color="standard"
          />
        </Box>
      )}
    </section>
  );
};

export default SalesConsultancies;
