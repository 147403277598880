import { doClearCart, useCart } from "../../Cart";
import Axios from "axios";
import { handleError } from "../../../utils/utils";

const useUserStore  = () => {
  const { dispatch } = useCart();
  
  const clearCookies = async () => {
    try {
      await Axios.post("/v2/google/logout",{ }, { withCredentials: true });
    } catch (error) {
      handleError(error);
    }
  };

  const startLogout = async  () => {
    await clearCookies();
    localStorage.clear();
    dispatch(doClearCart()); 
    window.location.reload();
  };
  return { startLogout };
};
export default useUserStore;
