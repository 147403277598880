import {
  coatingAndFlooringAttributesSchema,
  homeAndWellnessAttributesSchema,
  lightingAttributesSchema,
  petsAttributesSchema,
} from "../services/formValidation";

export const getProductFormSchema = (categoryId: number) => {
  switch (categoryId) {
    case 8:
    case 9:
      return coatingAndFlooringAttributesSchema;
    case 3:
      return lightingAttributesSchema;
    case 11:
      return homeAndWellnessAttributesSchema;
    case 12:
      return petsAttributesSchema;
  }
};

export const createComissionValues = (min: number, max: number) => {
  let arrayOfValues = [];
  let value = min;
  let index = 0;

  while (value < max) {
    arrayOfValues.push({ id: index, name: `${value}%`, value: value });
    value = value + 5;
    index++;
  }

  return arrayOfValues;
};

// ERROR HANDLER
export const handleError = (error: any) => {
  console.error(error.message);
  throw error;
};

export function isWallCoveringOrFloor(category: string): boolean {
  return category === "Revestimientos" || category === "Pisos";
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const shortenText = (text: string, maxLength: number = 50): string => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};
