import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";

import EcommerceChat from "../../components/EcommerceChat/EcommerceChat";
import chatModalStore from "../../ecommerce/store/ChatModalStore";
import { roles, useAuth } from "../../services/auth";
import { useGetSalesConsultancyByHashid } from "../../utils/react-query/querySalesConsultancy/queries";
import EcommerceConsultancySummaryHeaderInfo from "./components/EcommerceConsultancySummaryHeaderInfo/EcommerceConsultancySummaryHeaderInfo";

import styles from "./EcommerceConsultancySummary.module.scss";

function EcommerceConsultancySummary() {
  const { currentRoleId } = useAuth();
  const { setChatHashid } = useSnapshot(chatModalStore);
  const { hashid } = useParams<{ hashid: string }>();

  const { data: salesConsultancy, isLoading: isLoadingSalesConsultancy } =
    useGetSalesConsultancyByHashid(hashid);

  useEffect(() => {
    setChatHashid(hashid);
  }, [setChatHashid, hashid]);

  if (isLoadingSalesConsultancy) return <div>Loading...</div>;

  const { client, salesperson, product } = salesConsultancy;

  const personName =
    currentRoleId !== roles.client
      ? `${client.first_name} ${client.last_name}`
      : `${salesperson.first_name} ${salesperson.last_name}`;
  const currentRol = currentRoleId === roles.client ? "Asesor" : "Cliente";
  return (
    <section className="layout-dashboard-container">
      <EcommerceConsultancySummaryHeaderInfo
        personName={personName}
        currentRol={currentRol}
        product={product}
      />
      <div className={styles.ecommerceConsultancyContainer}>
        <section className={styles.ecommerceConsultancyContent}>
          <EcommerceChat />
          {/*Todo: in the future this section it is going to appear*/}
          {/*<ChatUserDetails />*/}
        </section>
      </div>
    </section>
  );
}

export default EcommerceConsultancySummary;
