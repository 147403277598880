import { yupResolver } from "@hookform/resolvers";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import { useForm } from "react-hook-form";
import CustomButton from "../Button/CustomButton";
import useClientLogin from "../../hooks/useClientLogin";
import { loginFormSchema } from "../../services/formValidation";
import InputForm from "../InputForm/InputForm";
import styles from "./clientLogin.module.scss";
import ShowError from "../showError/ShowError";

const ClientLoginCard = ({ secondaryAction, onSuccess }) => {
  const { isLoading, error, onLogin, OnClickRegister, resetPassword } =
    useClientLogin(onSuccess);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(loginFormSchema),
  });

  const onSubmit = async (data) => {
    onLogin(true, data);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Iniciar sesión</h1>
      {error && <ShowError error={error} />}
      <form
        className={styles.form}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <InputForm
          icon={EmailIcon}
          name="email"
          id="email"
          placeholder="Correo"
          disabled={isLoading}
          register={register}
          errors={errors}
        />
        <InputForm
          icon={LockIcon}
          name="password"
          id="password"
          placeholder="Contraseña"
          type="password"
          disabled={isLoading}
          register={register}
          errors={errors}
        />

        <div onClick={() => resetPassword()} className={styles.forgotPassword}>
          <span>¿Olvidaste la contraseña?</span>
        </div>

        <CustomButton
          color="primary"
          isLoading={isLoading}
          disabled={isLoading}
          shape="little-rounded"
          type="onSubmit"
          block
        >
          Ingresar
        </CustomButton>
      </form>
      <div className={styles.footerContent}>
        <div className={styles.text}>
          <span>¿Aún no tienes una cuenta con nosotros?</span>
        </div>
        {secondaryAction ? (
          <CustomButton
            color="secondary"
            shape="little-rounded"
            onClick={secondaryAction}
            block
          >
            Regístrate aquí
          </CustomButton>
        ) : (
          <CustomButton
            color="secondary"
            shape="little-rounded"
            block
            onClick={OnClickRegister}
          >
            Regístrate aquí
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default ClientLoginCard;
