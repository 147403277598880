import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import { MessageCircle } from "lucide-react";
import React from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../../components/Button/CustomButton";
import SalesPersonAvatar from "../../../../components/SalesPersonAvatar/SalesPersonAvatar";
import { roles, useAuth } from "../../../../services/auth";
import useCompanyStore from "../../../../Store/supplierCompany/companyData";
import { shortenText } from "../../../../utils/utils";
import styles from "./SalesConsultanciesCard.module.scss";

type SalesConsultanciesCardProps = {
  consultancy: SalesConsultancy;
};

const SalesConsultanciesCard = ({
  consultancy,
}: SalesConsultanciesCardProps) => {
  const { companyName } = useCompanyStore();
  const history = useHistory();
  const { currentRoleId } = useAuth();
  const { client, salesperson, product } = consultancy;
  const personName =
    currentRoleId !== roles.client
      ? `${client.first_name} ${client.last_name}`
      : `${salesperson.first_name} ${salesperson.last_name}`;
  const roleLabel = currentRoleId !== roles.client ? "Cliente" : "Asesor";
  const rolePath = currentRoleId === roles.client ? "cliente" : "vendedor";
  const consultancieLink =
    currentRoleId === roles.client
      ? `/${companyName}/${rolePath}/asesoria/${consultancy.hashid}/chat`
      : `/${rolePath}/asesoria/${consultancy.hashid}/chat`;

  return (
    <Card>
      <div className={styles.productTitle}>
        <h2>{shortenText(product.name, 30)}</h2>
      </div>
      <div className={styles.mainImage}>
        <div>
          <img src={product.image} alt={product.name} />
        </div>
      </div>
      <div className={styles.advisorInfo}>
        <div>
          {currentRoleId === roles.client ? (
            <SalesPersonAvatar
              avatar={salesperson.avatar}
              name={personName}
              online_status={salesperson.online_status}
              showBorder={false}
            />
          ) : (
            <Avatar>{personName?.charAt(0)}</Avatar>
          )}
          <div className={styles.advisorName}>
            <h4>{roleLabel}</h4>
            <p>{personName}</p>
          </div>
        </div>
      </div>
      <div className={styles.advisorButton}>
        <CustomButton
          color="outlined"
          shape="little-rounded"
          size="medium"
          block
          icon={MessageCircle}
          onClick={() => history.push(consultancieLink)}
        >
          Ver chat
        </CustomButton>
      </div>
    </Card>
  );
};

export default SalesConsultanciesCard;
