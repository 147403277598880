import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import React from "react";
import CustomTooltip from "../Tooltip/CustomTooltip";

type CircularButtonProps = {
  icon: any;
  onClick?: () => void;
  size?: "small" | "medium";
  tooltipName?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    container: {
      backgroundColor: "#f0f0f0",
      transition: "background-color 0.3s ease-in-out",
      "&:hover": {
        backgroundColor: "#e7e7e7",
      },
    },
  })
);

const CircularButton = ({
  icon: Icon,
  onClick,
  size = "small",
  tooltipName = "",
}: CircularButtonProps) => {
  const classes = useStyles();
  return (
    <CustomTooltip title={tooltipName}>
      <IconButton
        aria-label="delete"
        className={classes.container}
        onClick={onClick}
      >
        <Icon fontSize={size} />
      </IconButton>
    </CustomTooltip>
  );
};

export default CircularButton;
