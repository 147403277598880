import { Paperclip, SendHorizontal } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import styles from "./EcommerceChat.module.scss";

type ChatFooterProps = {
  messageToSend: string;
  sendMessage: (file?: File) => void;
  setMessageToSend: (message: string) => void;
};

const ChatFooter = ({
  sendMessage,
  messageToSend,
  setMessageToSend,
}: ChatFooterProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const MAX_CHARS = 240;

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "40px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = Math.min(scrollHeight, 120) + "px";
    }
  }, [messageToSend]);

  // Send a message when the user press the Enter key
  const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      sendMessage(selectedFile || undefined);
      setSelectedFile(null);
    }
  };

  // Functions to send a message
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_CHARS) setMessageToSend(value);
  };

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      sendMessage(e.target.files[0]);
      setSelectedFile(null);
    } else {
      setSelectedFile(null);
    }
  };

  return (
    <div className={styles.chatFooter}>
      <div className={styles.chatFooterContent}>
        <div className={styles.textAreaContainer}>
          <textarea
            ref={textareaRef}
            value={messageToSend}
            onChange={handleTextareaChange}
            onKeyDown={(e) => handleEnter(e)}
            placeholder="Escribe un mensaje..."
            className={styles.textAreaField}
            rows={1}
          />
          <span>
            {messageToSend.length}/{MAX_CHARS}
          </span>
        </div>
        <input
          type="file"
          name="salesChatFile"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <button
          type="button"
          className={styles.chatFooterClipButton}
          onClick={handleFileInputClick}
        >
          <Paperclip size={25} />
        </button>

        <button
          className={styles.chatFooterSendButton}
          type="button"
          onClick={() => {
            sendMessage(selectedFile || undefined);
            setSelectedFile(null);
          }}
        >
          <SendHorizontal size={25} />
        </button>
      </div>
    </div>
  );
};

export default ChatFooter;
