import CircularProgress from "@material-ui/core/CircularProgress";

import "./Chat.css";
import axios from "axios";
import { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";

import { io } from "socket.io-client";
import { useSnapshot } from "valtio";
import chatModalStore from "../../ecommerce/store/ChatModalStore";
import { roles, useAuth } from "../../services/auth";

import { BASE_API_URL } from "../../services/baseURL";
import ChatEmptyState from "./ChatEmptyState";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import ChatMessageList from "./ChatMessageList";

import styles from "./EcommerceChat.module.scss";
import { errorToast } from "../../services/toasts";

const socket = io(BASE_API_URL);

type EcommerceChatProps = {
  showControls?: boolean;
};

function EcommerceChat({ showControls = false }: EcommerceChatProps) {
  const { currentRoleId } = useAuth();
  const { chatHashid } = useSnapshot(chatModalStore);
  const [chatMessages, setChatMessages] = useState<ChatMessages[]>([]);
  const [messageToSend, setMessageToSend] = useState("");
  const [roomUuid, setRoomUuid] = useState("");
  const [loggedUserUuid, setLoggedUserUuid] = useState("");
  const [salespersonInfo, setSalespersonInfo] =
    useState<SalesPersonInfo | null>(null);
  const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);
  const [isLoadingChatMessages, setIsLoadingChatMessages] = useState(false);
  const [isDefaultMessageSet, setIsDefaultMessageSet] = useState(false);

  const clientAvatar = clientInfo?.first_name.charAt(0).toUpperCase();
  const salesAvatar = salespersonInfo?.avatar;

  useEffect(() => {
    const fetchChatDetails = async () => {
      try {
        const response = await axios.get<GetSalesConsultancyResponse>(
          `/v2/sales-consultancies/${chatHashid}`
        );
        const { salesChat, salesperson, client } = response.data;

        setRoomUuid(salesChat?.room_uuid);
        setSalespersonInfo(salesperson);
        setClientInfo(client);

        if (currentRoleId === roles.client) {
          setLoggedUserUuid(salesChat?.client_uuid);
        } else {
          setLoggedUserUuid(salesChat?.salesperson_uuid);
        }
      } catch (error) {
        console.error("Error fetching chat details:", error);
      }
    };

    fetchChatDetails();
  }, [chatHashid, currentRoleId]);

  useEffect(() => {
    if (!roomUuid) return;

    const fetchChatMessages = async () => {
      try {
        setIsLoadingChatMessages(true);
        const chatResponse = await axios.get(
          `/v1/chat-message?chat=${roomUuid}`
        );
        setChatMessages(chatResponse.data.body.statusMessage || []);

        if (
          chatResponse.data.body.statusMessage.length === 0 &&
          !isDefaultMessageSet &&
          currentRoleId === roles.client
        ) {
          setDefaultMessage();
        }
      } catch (error) {
        console.error("Error fetching chat messages:", error);
      } finally {
        setIsLoadingChatMessages(false);
      }
    };

    fetchChatMessages();
  }, [roomUuid, isDefaultMessageSet, currentRoleId]);

  const setDefaultMessage = () => {
    const productUrl = window.location.href;
    const defaultMessage = `Hola, estoy interesado en una asesoría sobre el siguiente producto: ${productUrl}`;
    setMessageToSend(defaultMessage);
    setIsDefaultMessageSet(true);
  };

  const dataChat = {
    currentUserId: loggedUserUuid,
    currentChatId: roomUuid,
  };

  const sendMessage = async (file?: File) => {
    if (!messageToSend.trim() && !file) {
      errorToast("Debe enviar un mensaje o un archivo.");
      return;
    }

    const formData = new FormData();
    formData.append("chat", roomUuid);
    formData.append("user", loggedUserUuid);

    if (messageToSend.trim()) {
      formData.append("message", messageToSend.trim());
    }

    if (file) {
      formData.append("salesChatFile", file);
    }

    try {
      await axios.post("/v1/chat-message", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setMessageToSend("");
    } catch (error) {
      errorToast("Ocurrió un error al enviar el mensaje");
    }
  };

  useEffect(() => {
    // Listen for new messages with Socket
    socket.on(`${roomUuid}`, (data: ChatMessages) => {
      setChatMessages((prevArray) => [...prevArray, data]);
    });
  }, [roomUuid]);

  return (
    <div className={styles.chatContainer}>
      <ChatHeader
        showControls={showControls}
        clientInfo={clientInfo}
        salespersonInfo={salespersonInfo}
        clientAvatar={clientAvatar!}
        salesAvatar={salesAvatar!}
      />

      <ScrollToBottom className={styles.messageContainer}>
        {isLoadingChatMessages ? (
          <div className={styles.loadingState}>
            <CircularProgress />
          </div>
        ) : chatMessages.length > 0 ? (
          <ChatMessageList chatMessages={chatMessages} dataChat={dataChat} />
        ) : (
          <ChatEmptyState currentRoleId={currentRoleId} />
        )}
        <div className="message-mega"></div>
      </ScrollToBottom>

      <ChatFooter
        messageToSend={messageToSend}
        sendMessage={sendMessage}
        setMessageToSend={setMessageToSend}
      />
    </div>
  );
}

export default EcommerceChat;
