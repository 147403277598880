import React from "react";
import { default as MUISearchBar } from "material-ui-search-bar";
import { roles, useAuth } from "../../services/auth";

type SearchBarProps = {
  value: string;
  setSearchTerm: (newValue: string) => void;
};

const SearchBar = ({ value, setSearchTerm }: SearchBarProps) => {
  const { currentRoleId } = useAuth();
  const placeholder = `Buscar por ${
    currentRoleId === roles.client ? "asesor" : "cliente"
  } o producto...`;
  return (
    <MUISearchBar
      value={value}
      onChange={(newValue) => setSearchTerm(newValue)}
      onCancelSearch={() => setSearchTerm("")}
      placeholder={placeholder}
    />
  );
};

export default SearchBar;
