import { useState, Fragment } from "react";
import { useAuth, roles } from "../../services/auth";
import { NavLink } from "react-router-dom";
import { createUseStyles } from "react-jss";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ClientNavbar from "./ClientNavbar";
import { useUserStore } from "../../Store";

import styled from "styled-components";
import logo from "../../assets/svg/logow.svg";

function Navbar() {
  const classes = useStyles();
  const { authToken, currentRoleId, isClient } = useAuth();
  const { startLogout } = useUserStore();

  const [isOpen, setIsOpen] = useState(false);

  const dashboardRoutes: {
    [key: typeof currentRoleId]: string;
  } = {
    [roles.designer]: "/disenador/dashboard",
    [roles.supplier]: "/proveedor/dashboard",
    [roles.lsh]: "/lsh/dashboard",
    [roles.admin]: "/admin/dashboard",
  };

  const logout = () => {
    startLogout();
  };

  return (
    <Container>
      <nav className={isOpen ? classes.menuOpen : classes.menu}>
        <HeaderContainer>
          <div>
            <a href="https://fliping.co/">
              <img src={logo} alt="logo" className={classes.logo} />
            </a>
          </div>
          <SocialsContainer>
            <Icons />
          </SocialsContainer>
          <div style={{ height: 40, width: 30 }}>
            <button onClick={() => setIsOpen(!isOpen)} className={classes.icon}>
              <i
                style={{ color: "#fff" }}
                className={isOpen ? "fa fa-caret-up" : "fa fa-bars"}
              ></i>
            </button>
          </div>
        </HeaderContainer>

        <MenuUp>
          <div className={isOpen ? classes.navigationOpen : classes.navigation}>
            <a
              href="https://fliping.co/elige-profesional/"
              target="__blank"
              style={{ cursor: "pointer", color: "#fff" }}
            >
              <span>Solicitar Asesoría</span>
            </a>
            <NavLink to="/productos" style={{ color: "#fff" }}>
              <span>Ver productos</span>
            </NavLink>
            <a
              href="https://fliping.co/trabaja-con-nosotros-disenador/"
              target="__blank"
              style={{ cursor: "pointer", color: "#fff" }}
            >
              <span>Trabaja con Fliping</span>
            </a>
            <a
              href="https://fliping.co/#inspirate"
              target="__blank"
              style={{ cursor: "pointer", color: "#fff" }}
            >
              <span>Inspírate</span>
            </a>
            {dashboardRoutes[currentRoleId] && (
              <NavLink
                to={dashboardRoutes[currentRoleId]}
                style={{ color: "#fff" }}
              >
                <span>Dashboard</span>
              </NavLink>
            )}
            {isClient && <ClientNavbar />}
            {authToken ? (
              <a
                href="https://fliping.co/"
                style={{ color: "#fff" }}
                onClick={logout}
              >
                <span>Cerrar sesión</span>
              </a>
            ) : (
              <a href="/login" style={{ color: "#fff" }}>
                <span>Ingresa</span>
              </a>
            )}
            <SocialsContainerDesktop>
              <Icons />
            </SocialsContainerDesktop>
          </div>
        </MenuUp>
      </nav>
    </Container>
  );
}

function Icons() {
  return (
    <Fragment>
      <a
        href="https://www.instagram.com/fliping_co/"
        target="__blank"
        style={{ padding: 5 }}
      >
        <InstagramIcon style={{ color: "#fff" }} />
      </a>
      <a
        href="https://www.facebook.com/Fliping.co/"
        target="__blank"
        style={{ padding: 5 }}
      >
        <FacebookIcon style={{ color: "#fff" }} />
      </a>
      <a
        href="https://www.linkedin.com/company/fliping-co/"
        target="__blank"
        style={{ padding: 5 }}
      >
        <LinkedInIcon style={{ color: "#fff" }} />
      </a>
      <a
        href="https://www.youtube.com/channel/UClp95CsyM-Gare4C58V0bOA"
        target="__blank"
        style={{ padding: 5 }}
      >
        <YouTubeIcon style={{ color: "#fff" }} />
      </a>
    </Fragment>
  );
}

const MenuUp = styled.div`
  margin-top: 8px;
`;

const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  & > nav {
    min-height: 10vh;
    background: #424242;
  }
`;
const SocialsContainer = styled.div`
  margin-top: 10px;

  display: block;
`;

const SocialsContainerDesktop = styled.div`
  @media (min-width: 320px) {
    display: none;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 320px) {
    width: 100%;
  }
`;

const useStyles = createUseStyles({
  menu: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 1.5rem",
    background: "#424242",
  },
  logo: {
    width: "7rem",
  },
  icon: {
    display: "none",
    border: "none",
    background: "none",
    cursor: "pointer",
  },
  navigation: {
    display: "flex",
    "& a": {
      color: "black",
      padding: "0 1rem",
      textDecoration: "none",
      "&:visited": {
        color: "black",
      },
    },
  },
  navigationOpen: {
    display: "flex",
    flexDirection: "column",
    "& a": {
      margin: "1.125rem 0",
      color: "black",
      textDecoration: "none",
      "&:visited": {
        color: "black",
      },
    },
  },
  menuOpen: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem 1.5rem",
    alignItems: "baseline",
    borderBottom: "0.3rem solid rgba(0, 0, 0, 0.1)",
  },
  "@media (min-width: 320px)": {
    icon: {
      display: "block",
      "& i": {
        marginTop: "10px",
        color: "#40423f",
        fontSize: "1.5rem",
      },
    },
    navigation: {
      display: "none",
      "& i": {
        visibility: "hidden",
      },
    },
  },
  "@media (min-width: 768px)": {
    menu: {
      padding: "1rem 2.5rem",
    },
  },
});

export default Navbar;
