import React from "react";
import KnockIcon from "../../assets/svg/knock.svg";
import { useSendNotification } from "../../utils/react-query/queryChat/queries";
import CustomTooltip from "../Tooltip/CustomTooltip";
import styles from "./KnockButton.module.scss";
import Swal from "sweetalert2";

const KnockButton = ({ chatHashid }: { chatHashid: string }) => {
  const { mutateAsync: sendNotification } = useSendNotification();
  const handleSendNotification = (): void => {
    Swal.fire({
      title: "¿Deseas notificar al asesor sobre tu consulta?",
      text: "Enviamos un recordatorio al asesor para que te atienda a la brevedad.",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Sí, notificar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await sendNotification({ hashid: chatHashid });
      }
    });
  };
  const title =
    "Si el asesor tarda en responder, haz clic aquí para recordarle.";
  return (
    <CustomTooltip title={title}>
      <button className={styles.knockButton} onClick={handleSendNotification}>
        <img src={KnockIcon} alt="hello" width={25} height={25} />
      </button>
    </CustomTooltip>
  );
};

export default KnockButton;
