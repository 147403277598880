import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import { ExpandMore, MenuBook } from "@material-ui/icons";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import jwt from "jwt-decode";
import { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { getCategoriesByRole, roles, useAuth } from "../../../services/auth";
import { useStyles } from "../../../services/muiStyles";
import useCompanyStore from "../../../Store/supplierCompany/companyData";

interface OpenMenuState {
  [key: string]: boolean;
}

const DrawerLinks = (isMobile: { isMobile: boolean }) => {
  const { authToken } = useAuth();
  const decoded: DecodedUser = jwt(authToken);
  const { companyName } = useCompanyStore();
  const categories = getCategoriesByRole(decoded.role_id, companyName!);
  const classes = useStyles();
  const location = useLocation();
  const pathname = location.pathname;
  const [openMenu, setOpenMenu] = useState<OpenMenuState>({});

  const handleMenuToggle = (menu: any) => {
    setOpenMenu((prevState) => ({ ...prevState, [menu]: !prevState[menu] }));
  };

  const getFirstTwoSegments = (path: string) => {
    const segments = path.split("/").filter(Boolean);
    return segments.slice(0, 2).join("/");
  };

  return (
    <div
      style={{
        marginInline: "0.8rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        paddingBlock: "1.5rem",
      }}
    >
      <List>
        {categories.map((item: Category, index) => {
          const isActive =
            item.subItems &&
            item.subItems.some((subItem) => {
              const subItemFirstTwoSegments = getFirstTwoSegments(subItem.link);
              return pathname.startsWith(`/${subItemFirstTwoSegments}`);
            });

          const isCollapsed = openMenu[item.text];

          return (
            <div key={item.text}>
              {item.subItems ? (
                <>
                  <div
                    className={`${isActive ? "navlinkActive" : "navLink"} 
            ${isCollapsed && !isActive ? "navLinkCollapsed" : ""}
            `}
                    onClick={() => handleMenuToggle(item.text)}
                  >
                    <div className="navLinkContent">
                      <div className="iconLink">{item.icon}</div>
                      <div>{item.text}</div>
                    </div>
                    {openMenu[item.text] ? (
                      <ExpandMore />
                    ) : (
                      <NavigateNextIcon />
                    )}
                  </div>
                  <Collapse
                    style={{ paddingLeft: "24px" }}
                    in={openMenu[item.text]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" className={classes.subMenuList}>
                      {item.subItems.map((subItem, subIndex) => (
                        <ListItem
                          className={classes.subMenuLink}
                          key={subItem.link}
                        >
                          <NavLink
                            to={subItem.link}
                            className="subMenuLink"
                            activeClassName="subMenuLinkActive"
                          >
                            <div>{subItem.text}</div>
                          </NavLink>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <NavLink
                  key={item.text}
                  to={item.link!}
                  className="navLink"
                  activeClassName="navlinkActive"
                >
                  <div className="navLinkContent">
                    <div className="iconLink">{item.icon}</div>
                    <div>{item.text}</div>
                  </div>
                </NavLink>
              )}
            </div>
          );
        })}
      </List>
      <List>
        {roles.designer === decoded.role_id && (
          <ListItem
            button
            onClick={() => {
              window.open(
                "https://tropical-silver-6b7.notion.site/Centro-de-ayuda-9433197db8854848b2779c4999a427b4"
              );
            }}
          >
            <ListItemIcon>
              <MenuBook className={classes.icons} />
            </ListItemIcon>
            <ListItemText primary={"Centro de ayuda"} />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default DrawerLinks;
