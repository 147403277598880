import { useEffect } from "react";
import { useParams } from "react-router";
import useCompanyStore from "../Store/supplierCompany/companyData";
import { useGetAllCompanyPreferences } from "../utils/react-query/queryEcommerce/queries";

const useCompanyPreferences = (shouldFetch: boolean = true) => {
  let { companyName } = useParams<{ companyName: string }>();
  if (companyName) {
    companyName = companyName.replace(/-/g, " ");
  } else {
    companyName = "";
  }

  const { data, isLoading } = useGetAllCompanyPreferences(
    companyName,
    shouldFetch
  );
  const setCompanyData = useCompanyStore((state) => state.set);

  useEffect(() => {
    if (data) {
      setCompanyData({
        companyPrimaryColor: data?.company_primary_color,
        companySecondaryColor: data?.company_secondary_color,
        companyFontType: data?.company_font_type,
        companyLogo: data?.company_logo.url,
        companyBanner: data?.company_banner,
        companyName: data?.company_name,
        companyNavbarLinks: data?.company_navbarLinks,
        companySocialMediaLinks: data?.company_social_Media_Links,
      });

      // Update CSS variables
      document.documentElement.style.setProperty(
        "--primary-color",
        data.company_primary_color
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        data.company_secondary_color
      );
    }
  }, [data, setCompanyData]);

  return { isLoading };
};

export default useCompanyPreferences;
