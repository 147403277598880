import React from "react";
import styles from "./SalesPersonAvatar.module.scss";

type SalesPersonAvatarProps = {
  avatar: string;
  name: string;
  online_status: string;
  onClick?: () => void;
  showBorder?: boolean;
};

const SalesPersonAvatar = ({
  avatar,
  name,
  online_status,
  onClick,
  showBorder = true,
}: SalesPersonAvatarProps) => {
  return (
    <button className={styles.avatarButton} onClick={onClick}>
      <div className={styles.avatarWrapper}>
        <img
          src={avatar}
          alt={name}
          className={`${styles.avatarImg} ${
            showBorder ? styles.withBorder : styles.noBorder
          }`}
        />
        <div
          className={`${styles.statusIndicator} ${
            online_status ? styles.online : styles.offline
          }`}
        ></div>
      </div>
    </button>
  );
};

export default SalesPersonAvatar;
