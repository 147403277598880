import { PictureAsPdf } from "@material-ui/icons";
import { formatChatDate } from "../../utils/formatChatDate";
import styles from "./EcommerceChat.module.scss";
import { SRLWrapper } from "simple-react-lightbox";
type ChatMessageProps = {
  isCurrentUser: boolean;
  message: ChatMessages;
};

const ChatMessage = ({ isCurrentUser, message }: ChatMessageProps) => {
  const renderMessageWithLinks = (text: string) => {
    if (!text) return;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#006bff", textDecoration: "underline" }}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const isImage = (url: string) => {
    return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
  };

  return (
    <div
      className={`${styles.message} ${
        !isCurrentUser ? styles.justifyStart : styles.justifyEnd
      }`}
    >
      {message.message && (
        <div
          className={`${styles.messageInfo} ${
            !isCurrentUser ? styles.backgroundOther : styles.backgroundUser
          }`}
        >
          <p>{renderMessageWithLinks(message.message)}</p>
          <span>{formatChatDate(message.date)}</span>
        </div>
      )}

      {message.file && (
        <div
          className={`${styles.messageFile} ${
            !isCurrentUser ? styles.backgroundOther : styles.backgroundUser
          }`}
        >
          {isImage(message.file) ? (
            <SRLWrapper>
              <img src={message.file} alt="Imagen" />
            </SRLWrapper>
          ) : (
            <a href={message.file} target="_blank" rel="noopener noreferrer">
              <PictureAsPdf fontSize="large" />
            </a>
          )}
          <span>{formatChatDate(message.date)}</span>
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
