import jwtDecode from "jwt-decode";
import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { asyncCart } from "../ecommerce/services/cartSyncService";
import { useAuth } from "../services/auth";
import Axios from "axios";

const useClientLogin = (onSuccess) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setAuthToken } = useAuth();

  const onLogin = useCallback(async (isOnSubmit = false, data) => {
    try {
      setIsLoading(true);
      
      if (isOnSubmit) {
        const auth = await Axios.post("/v1/auth/login", data);

        if (auth.status === 200) {
          data = auth.data;
          window.location.reload();
        }
      } 
      
      setAuthToken(data.token);
      let { token, ...userTokens } = data;
      localStorage.setItem("user", JSON.stringify(userTokens));
      let decoded = data.token && jwtDecode(data.token);

      if (
        (decoded.role_id === 1 || decoded.role_id === 2) &&
        typeof onSuccess === "function"
      ) {
        onSuccess();
        await asyncCart();
      } else if (decoded.role_id === 2) {
        history.push("/productos");
      } else if (decoded.role_id === 3) {
        history.push("/proveedor/dashboard");
      } else if (decoded.role_id === 4) {
        history.push("/lsh/dashboard");
      } else if (decoded.role_id === 5) {
        history.push("/admin/dashboard");
      } else if (decoded.role_id === 6) {
        history.push("vendedor/dashboard")
      }
    } catch (error) {
      let requestError = error.response ? error.response.data.message : null;
      setError(requestError ? requestError : error.message);
    } finally {
      setIsLoading(false);
    }
  }, [history, setAuthToken, onSuccess]);


  const OnClickRegister = () => {
    history.push("/register");
  };

  const resetPassword = () => {
    history.push("/password-reset");
  };

  return {
    isLoading,
    error,
    onLogin,
    OnClickRegister,
    resetPassword,
  };
};

export default useClientLogin;
