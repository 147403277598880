import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import React from "react";

type StyleProps = {
  bgColor?: string;
  textColor?: string;
  fontSize?: string;
};

type CustomTooltipProps = TooltipProps &
  StyleProps & {
    children: React.ReactNode;
  };

const useStyles = makeStyles(() => ({
  customTooltip: (props: StyleProps) => ({
    backgroundColor: props.bgColor || "#484848",
    color: props.textColor || "#fff",
    fontSize: props.fontSize || "0.75rem",
    padding: "8px 12px",
    borderRadius: "4px",
  }),
  arrow: (props: StyleProps) => ({
    color: props.bgColor || "#333",
  }),
}));

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  children,
  bgColor,
  textColor,
  fontSize,
  ...props
}) => {
  const classes = useStyles({ bgColor, textColor, fontSize });

  return (
    <Tooltip
      {...props}
      classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}
      arrow
      placement="top"
      TransitionComponent={Zoom}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
